import Carousel from 'react-bootstrap/Carousel';
import React from 'react';
import './Home.scss';

import slide1 from './slide1.jpg';
import slide2 from './slide2.jpg';
import slide3 from './slide3.jpg';
import slide4 from './slide4.jpg';

function Home() {
  return (
    <Carousel id='home' 
    autoPlay={true}
    interval={3000}
     className='carousel' fade>
      <Carousel.Item className='carousel_item'>
        <div className="container">

        <div className="content">
            <h1>WELCOME TO ORTUM MISSION HOSPITAL</h1>
            <h4>For provision Compassionate Care to All</h4>
            <div className="button ">
              <button>
              <a  href='#contact' className='btn '>GET IN TOUCH</a>
              </button>
            </div>

        </div>
        <div className="image">
            <img 
            src={slide1}

             alt="CYBER SERVICES" />
        </div>
        </div>

      </Carousel.Item>
      <Carousel.Item className='carousel_item'>
      <div className="container">

      <div className="content">
           <h1>WELCOME TO ORTUM MISSION HOSPITAL</h1>
            <h4>State-of-the-Art Facilities and Equipment</h4>
            <div className="button ">
            <button>
              <a  href='#contact' className='btn '>GET IN TOUCH</a>
              </button>
            </div>

        </div>
        <div className="image">
            <img 
            src={slide2}
             alt="LAW AND CONSULTANTCY  " />
        </div>
        </div>
    </Carousel.Item>
      <Carousel.Item className='carousel_item'>
      <div className="container">

      <div className="content">
           <h1>WELCOME TO ORTUM MISSION HOSPITAL</h1>
            <h4>Expert Care from Skilled Medical Professionals</h4>
            <div className="button ">
                   <button>
              <a  href='#contact' className='btn '>GET IN TOUCH</a>
              </button>
            </div>

        </div>
        <div className="image">
            <img 
            src={slide3}

            alt="VEHICLE SERVICES" />
        </div>
        </div>

      </Carousel.Item>
      <Carousel.Item className='carousel_item'>
        <div className="container">

        <div className="content">
           <h1>WELCOME TO ORTUM MISSION HOSPITAL</h1>
            <h4>Partnering with the Community for Better Health</h4>
            <div className="button ">
                   <button>
              <a  href='#contact' className='btn '>GET IN TOUCH</a>
              </button>
            </div>
        </div>
        <div className="image">
            <img 
             src={slide4}

            alt="HOUSING" />
        </div>
        </div>

</Carousel.Item>

    </Carousel>
  );
}

export default Home;    
