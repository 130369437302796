import React, {useEffect, useState} from 'react';
import { Modal, Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import TenderBlock from "./TenderBlock";

const Tenders = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const authToken = localStorage.getItem('authToken');
    const [isModalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        publishDate: '',
        closingDate: '',
        status: 'OPEN',
        budget: 0,
        location: '',
        requirements: [],
    });
    const [tenders, setTenders] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const deleteTender = (tenderId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .delete(`${backendUrl}/api/tenders/delete/${tenderId}`, {
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    })
                    .then(() => {
                        // Update state to remove the deleted tender
                        setTenders((prevTenders) => prevTenders.filter((tender) => tender.id !== tenderId));

                        Swal.fire({
                            title: 'Deleted!',
                            text: 'The tender has been successfully deleted.',
                            icon: 'success',
                        });
                    })
                    .catch((error) => {
                        console.error('Error deleting tender:', error);
                        Swal.fire({
                            title: 'Error',
                            text: 'Failed to delete the tender.',
                            icon: 'error',
                        });
                    });
            }
        });
    };

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleRequirementChange = (index, field, value) => {
        const updatedRequirements = [...formData.requirements];
        updatedRequirements[index][field] = value;

        setFormData((prevData) => ({
            ...prevData,
            requirements: updatedRequirements,
        }));
    };

    const handleAddRequirement = () => {
        setFormData((prevData) => ({
            ...prevData,
            requirements: [...prevData.requirements, { description: '', quantity: 0, specifications: '' }],
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Send the formData to your backend API using axios or fetch
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        let authToken = localStorage.getItem('authToken');

        axios
            .post(`${backendUrl}/api/tenders/create`, formData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                console.log('Tender created successfully:', response.data);
                // Handle success, show a success message
                Swal.fire({
                    icon: 'success',
                    title: 'Tender Created',
                    text: 'The tender has been successfully created.',
                });
            })
            .catch((error) => {
                console.error('Error creating tender:', error);
                // Handle error, show an error message
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An error occurred while creating the tender. Please try again.',
                });
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${backendUrl}/api/tenders/list`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                setTenders(response.data);
                setLoading(false);

            } catch (error) {
                console.error('Error fetching job posts', error);
                setError(error.message || 'An error occurred while fetching job posts.');
                setLoading(false);
            }
        };

        fetchData();
    }, [authToken]);

    return (
        <div className="page-wrapper">
            <button className="btn btn-primary m-3" onClick={openModal}>
                Add Tender
            </button>

            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={8}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Tender Title"
                                    value={formData.title}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter tender description"
                                    value={formData.description}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="publishDate">
                                <Form.Label>Publish Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="2023-12-24"
                                    value={formData.publishDate}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="closingDate">
                                <Form.Label>Closing Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="2024-01-15"
                                    value={formData.closingDate}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="status">
                                <Form.Label>Status</Form.Label>
                                <Form.Control as="select" value={formData.status} onChange={handleChange}>
                                    <option value="OPEN">Open</option>
                                    <option value="CLOSED">Closed</option>
                                    {/* Add other status options as needed */}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="budget">
                                <Form.Label>Budget</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="50000"
                                    value={formData.budget}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="location">
                                <Form.Label>Location</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Tender Location"
                                    value={formData.location}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="requirements">
                                <Form.Label>Requirements</Form.Label>
                                {formData.requirements.map((requirement, index) => (
                                    <div key={index}>
                                        <Form.Group controlId={`description${index}`}>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="High-quality materials"
                                                value={requirement.description}
                                                onChange={(e) => handleRequirementChange(index, 'description', e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId={`quantity${index}`}>
                                            <Form.Label>Quantity</Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="100"
                                                value={requirement.quantity}
                                                onChange={(e) => handleRequirementChange(index, 'quantity', e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId={`specifications${index}`}>
                                            <Form.Label>Specifications</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Complies with industry standards"
                                                value={requirement.specifications}
                                                onChange={(e) => handleRequirementChange(index, 'specifications', e.target.value)}
                                            />
                                        </Form.Group>
                                    </div>
                                ))}
                                <Button variant="secondary" onClick={handleAddRequirement}>
                                    Add Requirement
                                </Button>
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Create Tender
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>

            <section className="job-section alternate">
                <div className="auto-container">
                    <div className="default-tabs tabs-box">
                        <div className="tabs-content wow fadeInUp">
                            {loading ? (
                                <p>Loading Tenders...</p>
                            ) : error ? (
                                <p className="text-danger text-center">{error}</p>
                            ) : tenders.length === 0 ? (
                                <h3 className={'text-danger text-center'}>No tenders available. Please come back later</h3>
                            ) : (
                                <div className="row">
                                    {tenders.map((tender) => (
                                        <TenderBlock key={tender.id} tenderData={tender} onDelete={() => deleteTender(tender.id)} />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={isModalOpen} onHide={closeModal} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Tender</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Duplicate the form inside the modal if needed for a separate modal layout */}
                    {/* ... */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={submitting}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Tenders;
