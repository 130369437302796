import React from 'react'
import './Footer.scss'
import About from './About/About'
import Links from './Links/Links'
import Questions from './Question/Question'
import Operations from './Operations/Operations.js'

function Footer() {
  return (
    <div className='footer'>
      <div className='footer_container'>
        <About />
        <Links />
        <Operations/>
        <Questions />
      
        </div>
        <div className="copyright">
          <p>
            &copy;ORTUM MISSION HOSPITAL 2022 All Rights Reserved
          </p>
        </div>


    </div>
  )
}

export default Footer