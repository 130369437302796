import React, {useEffect, useState} from 'react'
import './Gallery.scss'
import Heading from '../Heading/Heading'
import axios from 'axios';

function Gallery() {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    let authToken = localStorage.getItem("authToken");

    const [images, setImages] = useState([]);

    useEffect(() => {
        axios.get(`${backendUrl}/api/gallery/list`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then((response) => {

                setImages(response.data);
            })
            .catch((error) => {
                console.error('Error fetching images', error);
            });
    }, []);

    const ImageComponent = ({url, caption}) => {

        return (
            <div className="image-container">
                <img src={url} alt={caption}/>
                <div className="image-caption">
                    <p>
                        {caption}
                    </p>
                </div>
            </div>
        )
    }


    return (
        <div
            id='gallery'
            className='gallery'
        >
            <Heading upper='GALLERY' main='OUR GALLERY'/>
            <div className="gallery_content">


                {

                    images.map((image) => (
                        <ImageComponent
                            key={image.id}
                            url={`${backendUrl}/` + image.url}
                            // url={process.env.PUBLIC_URL+image.image_url}
                            caption={image.caption}
                        />
                    ))
                }
            </div>

        </div>
    )
}

export default Gallery