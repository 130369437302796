import React from 'react';
import './Navigation.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from './logo.png';

function Navigation({ head }) {
  return (
      <Navbar className={head} fixed="top" collapseOnSelect expand="lg" variant="light">
        <Container className="nav_container">
          <Navbar.Brand href="#home">
            <div className="logo">
              <img src={Logo} alt="Mwanji Suppliers" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto nav_links">
              <Nav.Link className="nav_link home_link" href="/88787878878hjk#home">
                Home
              </Nav.Link>
              <Nav.Link className="nav_link" href="/#services">
                Services
              </Nav.Link>
              <Nav.Link className="nav_link" href="/#about">
                About Us
              </Nav.Link>
              <Nav.Link className="nav_link" href="/#gallery">
                Gallery
              </Nav.Link>
              <Nav.Link className="nav_link" href="/careers">
                Careers
              </Nav.Link>
              <Nav.Link className="nav_link" href="/tenders">
                Tenders
              </Nav.Link>
              <Nav.Link className="nav_link" href="#contact">
                Contact Us
              </Nav.Link>
            </Nav>
            {/* Add the login button here */}
            <Nav>
              <Nav.Link className="nav_link" href="/login">
                Login
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}

export default Navigation;
