import React, { useEffect, useState } from 'react';
import '../Careers/Careers.css';
import axios from 'axios';
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import TenderBlock from "./TenderBlock";

function Tenders() {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const authToken = localStorage.getItem('authToken');
    const [header, setHeader] = useState("header");
    const [tenders, setTenders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const listenScrollEvent = () => {
        if (window.scrollY < 73) {
            return setHeader("navigation")
        } else if (window.scrollY > 70) {
            return setHeader("navigation1")
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${backendUrl}/api/tenders/list`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                setTenders(response.data);
                setLoading(false);

            } catch (error) {
                console.error('Error fetching job posts', error);
                setError(error.message || 'An error occurred while fetching job posts.');
                setLoading(false);
            }
        };

        fetchData();
    }, [authToken]);
    return (
        <div>
            <Navigation head={header} />
            <div className="page-wrapper">
                <h3 className={"page-title"}>Tenders page</h3>
                <section className="job-section alternate">
                    <div className="auto-container">
                        <div className="default-tabs tabs-box">
                            <div className="tabs-content wow fadeInUp">
                                {loading ? (
                                    <p>Loading Tenders...</p>
                                ) : error ? (
                                    <p className="text-danger text-center">{error}</p>
                                ) : tenders.length === 0 ? (
                                    <h3 className={'text-danger text-center'}>No tenders available. Please come back later</h3>
                                ) : (
                                    <div className="row">
                                        {tenders.map((tender) => (
                                            <TenderBlock key={tender.id} tenderData={tender} />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default Tenders;