import React, {useEffect, useState} from 'react';
import JobHeader from './JobHeader';
import JobDescription from './JobDescription';
import JobOverview from './JobOverview';

import '../Careers.css';
import axios from "axios";
import Navigation from "../../Navigation/Navigation";
import Footer from "../../Footer/Footer";
import {useParams} from "react-router-dom";

function JobDetail() {
    const { id } = useParams();
    const [header, setHeader] = useState("header");
    const [jobPost, setJobPost] = useState({
        "id": 1,
        "positionTitle": "Data Analyst",
        "type": "Internship",
        "location": "Nairobi",
        "deadline": "2023-12-29",
        "salary": "Attractive",
        "vacancies": 5,
        "jobDescription": {
            "id": 1,
            "description": "Data Analyst will be responsible for collecting",
            "keyResponsibilities": [
                "Gather and collect data from various sources.",
                "Clean, preprocess, and transform data for accuracy."
            ],
            "requirements": [
                "Bachelor's degree in a related field.",
                "Strong analytical and quantitative skills.",
                "Proficiency in data analysis tools (SQL, Excel, Python)."
            ],
            "skills": [
                "Experience with statistical analysis and machine learning.",
                "Familiarity with data warehousing and ETL processes."
            ]
        }
    });

    useEffect(() => {
        let authToken = localStorage.getItem("authToken");
        const backendUrl = process.env.REACT_APP_BACKEND_URL;

        axios.get(`${backendUrl}/api/job-posts/${id}`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => {
                console.log(response.data);
                setJobPost(response.data);
            })
            .catch(error => {
                console.error('Error fetching job posts', error);
            });
    }, [id]);

    const listenScrollEvent = (event) => {
        if (window.scrollY < 73) {
            return setHeader("navigation");
        } else if (window.scrollY > 70) {
            return setHeader("navigation1");
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    return (
        <div>
            <Navigation head={header} />
            <div className="page-wrapper">
                <section className="job-detail-section style-two">
                    <div className="job-detail-outer">
                        <div className="auto-container">
                            <div className="row">
                                <div className="content-column col-lg-8 col-md-12 col-sm-12">
                                    <div className="job-block-outer">
                                        {/* Job Header Component */}
                                        <JobHeader data={jobPost}/>
                                    </div>

                                    {/* Job Description Component */}
                                    <JobDescription jobDescription={jobPost.jobDescription}/>

                                </div>

                                <div className="sidebar-column col-lg-4 col-md-12 col-sm-12">
                                    <aside className="sidebar">
                                        <div className="btn-box">
                                            {/* Apply Button */}
                                            <a href={`/careers/apply/${jobPost.positionTitle.toLowerCase().replace(/\s/g, '-')}-${jobPost.id}`}
                                               className="theme-btn btn-style-one desktop-search">Apply For Job</a>
                                        </div>

                                        {/* Job Overview Component */}
                                        <JobOverview data={jobPost}/>

                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    );
}

export default JobDetail;
