import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Col, Container, Form, Row} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

function Example() {
    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        publishDate: '',
        closingDate: '',
        status: 'OPEN',
        budget: 0,
        location: '',
        bidders: [],
        requirements: [],
    });

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleBidderChange = (index, field, value) => {
        const updatedBidders = [...formData.bidders];
        updatedBidders[index][field] = value;

        setFormData((prevData) => ({
            ...prevData,
            bidders: updatedBidders,
        }));
    };

    const handleRequirementChange = (index, field, value) => {
        const updatedRequirements = [...formData.requirements];
        updatedRequirements[index][field] = value;

        setFormData((prevData) => ({
            ...prevData,
            requirements: updatedRequirements,
        }));
    };

    const handleAddBidder = () => {
        setFormData((prevData) => ({
            ...prevData,
            bidders: [...prevData.bidders, { bidderName: '', bidAmount: 0, contactPerson: '' }],
        }));
    };

    const handleAddRequirement = () => {
        setFormData((prevData) => ({
            ...prevData,
            requirements: [...prevData.requirements, { description: '', quantity: 0, specifications: '' }],
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Send the formData to your backend API using axios or fetch
        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        let authToken = localStorage.getItem('authToken');

        axios
            .post(`${backendUrl}/api/tenders/create`, formData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                console.log('Tender created successfully:', response.data);
                // Handle success, show a success message
                Swal.fire({
                    icon: 'success',
                    title: 'Tender Created',
                    text: 'The tender has been successfully created.',
                });
            })
            .catch((error) => {
                console.error('Error creating tender:', error);
                // Handle error, show an error message
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An error occurred while creating the tender. Please try again.',
                });
            });
    };

    return (
        <>
            {values.map((v, idx) => (
                <Button key={idx} className="me-2 mb-2" onClick={() => handleShow(v)}>
                    Full screen
                    {typeof v === 'string' && `below ${v.split('-')[0]}`}
                </Button>
            ))}
            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal</Modal.Title>
                </Modal.Header>
                <Modal.Body>Modal body content</Modal.Body>
            </Modal>

            <div className="page-wrapper">
                {/* Your form */}
                <Container>
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={8}>
                            <Form onSubmit={handleSubmit}>
                                {/* ... other form fields ... */}

                                {/* Bidders */}
                                <Form.Group controlId="bidders">
                                    <Form.Label>Bidders</Form.Label>
                                    {formData.bidders.map((bidder, index) => (
                                        <div key={index}>
                                            <Form.Group controlId={`bidderName${index}`}>
                                                <Form.Label>Bidder Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Company XYZ"
                                                    value={bidder.bidderName}
                                                    onChange={(e) => handleBidderChange(index, 'bidderName', e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId={`bidAmount${index}`}>
                                                <Form.Label>Bid Amount</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="48000"
                                                    value={bidder.bidAmount}
                                                    onChange={(e) => handleBidderChange(index, 'bidAmount', e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId={`contactPerson${index}`}>
                                                <Form.Label>Contact Person</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="John Doe"
                                                    value={bidder.contactPerson}
                                                    onChange={(e) => handleBidderChange(index, 'contactPerson', e.target.value)}
                                                />
                                            </Form.Group>
                                        </div>
                                    ))}
                                    <Button variant="secondary" onClick={handleAddBidder}>
                                        Add Bidder
                                    </Button>
                                </Form.Group>

                                {/* Requirements */}
                                <Form.Group controlId="requirements">
                                    <Form.Label>Requirements</Form.Label>
                                    {formData.requirements.map((requirement, index) => (
                                        <div key={index}>
                                            <Form.Group controlId={`description${index}`}>
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="High-quality materials"
                                                    value={requirement.description}
                                                    onChange={(e) => handleRequirementChange(index, 'description', e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId={`quantity${index}`}>
                                                <Form.Label>Quantity</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="100"
                                                    value={requirement.quantity}
                                                    onChange={(e) => handleRequirementChange(index, 'quantity', e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId={`specifications${index}`}>
                                                <Form.Label>Specifications</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Complies with industry standards"
                                                    value={requirement.specifications}
                                                    onChange={(e) => handleRequirementChange(index, 'specifications', e.target.value)}
                                                />
                                            </Form.Group>
                                        </div>
                                    ))}
                                    <Button variant="secondary" onClick={handleAddRequirement}>
                                        Add Requirement
                                    </Button>
                                </Form.Group>

                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Example;