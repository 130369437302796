import React from 'react';

function TenderBlock({ tenderData }) {

    return (
        <div className="tender-block col-lg-6 col-md-12 col-sm-12">
            <div className="inner-box">
                <div className="content">
                    <h4>
                        <a href={tenderData.link}>
                            {tenderData.title} - <span className="text-muted">Ortum Mission Hospital</span>
                        </a>
                    </h4>
                    <ul className="job-info job-other-info">
                        <li className="time">
                            <i className="fi fi-rr-file"></i> Status: {tenderData.status}
                        </li>
                        <li className="privacy">
                            <i className="fi fi-rr-money"></i> Budget: {tenderData.budget}
                        </li>
                        <li className="required">
                            <i className="fi fi-rr-map-marker"></i> Location: {tenderData.location}
                        </li>
                        <hr />
                        <li>
                            <i className="fi fi-rr-clock-three"></i> Closing Date: {tenderData.closingDate} | +{' '}
                            {calculateDaysToExpiry(tenderData.closingDate)} days to expiry
                        </li>
                    </ul>
                    <div className="row">
                        <a href={"tenders/view-tender/" + tenderData.id} className="col-sm-3 offset-sm-9">
                            <button className="btn btn-warning btn-sm text-white float-end">
                                <span className="flaticon-plus"></span> Apply
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

const calculateDaysToExpiry = (closingDate) => {
    const currentDate = new Date();
    const expiryDate = new Date(closingDate);
    const timeDiff = expiryDate - currentDate;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
};

export default TenderBlock;
