import React from 'react'
import './Question.scss'
import Adress from './Adress.svg'
import Call from './Call.svg'
import Email from './Email.svg'
import Whatsapp from './Whatsapp.svg'


function Question() {
  return (
    <div className='footer_question'>
      <h6>HAVE A QUESTION?</h6>
      <ul>
        <li><a href='https://maps.com' ><img src={Adress} alt="" />ORTUM MISSION HOSPITAL P. O. BOX 1312-30200 KITALE</a></li>
        <li><a href='tel:0791028088' ><img src={Call} alt="" /> 0791028088</a></li>
        <li><a href='mailto://ortumhospital2010@gmail.com' ><img src={Email} alt="" />ortumhospital2010@gmail.com</a></li>
        <li><a href='https://api.whatsapp.com/send?phone=254727386931' ><img src={Whatsapp} alt="" />Click or Copy '0727386931'</a></li>
      </ul>
    </div>
  )
}

export default Question