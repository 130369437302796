import React from "react";
import {Model} from "survey-core";
import {Survey} from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import {json} from "./json";
import {useParams} from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

function SurveyComponent() {
    const {id} = useParams();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    let authToken = localStorage.getItem("authToken");
    const survey = new Model(json);
    survey.onComplete.add(async (sender, options) => {
        try {
            // Extract the position from the ID
            const position = parseInt(id.split('-').pop());

            const application = {
                ...sender.data,
                position: position,
            };

            application.cv = application.cv[0];

            console.log(application)

            // Send the data to the server
            const response = await axios.post(`${backendUrl}/api/employees/create`, application, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });

            // Handle the response if needed
            console.log('Server Response:', response.data);

            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Application submitted successfully!',
            }).then((result) => {
                if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                    window.location.href = '/';
                }
            });

        } catch (error) {
            console.error('Error submitting application:', error);

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to submit application. Please try again.',
            });
        }

    });

    survey.onUploadFiles.add((_, options) => {
        const formData = new FormData();
        options.files.forEach((file) => {
            //formData.append(file.name, file);
            formData.append('pictureFile', file);
            formData.append('caption', '');
            formData.append('morepath', 'cvs')
        });

        axios.post(`${backendUrl}/upload`, formData, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then((response) => {
                console.log(response.data)
                options.callback(
                    options.files.map((file) => {
                        return {
                            file: file,
                            content: `${backendUrl}/`+file.name,
                            name: response.data.filename
                        };
                    })
                );
            })
            .catch((error) => {
                console.error("Error: ", error);
                options.callback([], [ 'An error occurred during file upload.' ]);
            });
    });

    async function deleteFile(fileURL, options) {
        try {
            const name = fileURL.split("=")[1];
            const apiUrl = `https://api.surveyjs.io/private/Surveys/deleteTempFile?name=${name}`;
            const response = await fetch(apiUrl, {
                method: "DELETE"
            });

            if (response.status === 200) {
                console.log(`File ${name} was deleted successfully`);
                options.callback("success");
            } else {
                console.error(`Failed to delete file: ${name}`);
                options.callback("error");
            }
        } catch (error) {
            console.error("Error while deleting file: ", error);
            options.callback("error");
        }
    }

    survey.onClearFiles.add(async (_, options) => {
        if (!options.value || options.value.length === 0)
            return options.callback("success");
        if (!options.fileName && !!options.value) {
            for (const item of options.value) {
                await deleteFile(item.content, options);
            }
        } else {
            const fileToRemove = options.value.find(
                (item) => item.name === options.fileName
            );
            if (fileToRemove) {
                await deleteFile(fileToRemove.content, options);
            } else {
                console.error(`File with name ${options.fileName} is not found`);
            }
        }
    });

    return (<Survey model={survey} />);
}

export default SurveyComponent;