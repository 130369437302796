import React from 'react';
import Swal from "sweetalert2";
import axios from "axios";

function JobBlockAdmin({ jobData }) {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    let authToken = localStorage.getItem("authToken");
    const deleteJob = (jobId) => {
        // Display confirmation dialog
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // User confirmed, execute delete
                axios.delete(`${backendUrl}/api/job-posts/delete/${jobId}`, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                })
                    .then(response => {
                        // Handle success
                        Swal.fire({
                            title: 'Deleted!',
                            text: response.data,
                            icon: 'success'
                        });
                    })
                    .catch(error => {
                        // Handle error
                        console.error('Error deleting job:', error);
                        Swal.fire({
                            title: 'Error',
                            text: 'Failed to delete the job.',
                            icon: 'error'
                        });
                    });
            }
        });
    };

    return (
        <div className="job-block col-lg-6 col-md-12 col-sm-12">
            <div className="inner-box">
                <div className="content">
                    <h4><a href={jobData.link}>{jobData.positionTitle} - <span className="text-muted">Ortum Mission Hospital</span></a></h4>
                    {/*<h4><a href={jobData.link}>{jobData.positionTitle} - <span className="text-muted">{jobData.companyName}</span></a></h4>*/}
                    <ul className="job-info job-other-info">
                        <li className="time"><i className="fi fi-rr-briefcase"></i> {jobData.type}</li>
                        <li className="privacy"><i className="fi fi-rr-money"></i> {jobData.salary}</li>
                        <li className="required"><i className="fi fi-rr-map-marker"></i> Location: {jobData.location}</li>
                        <hr />
                        <li> <i className="fi fi-rr-clock-three"></i> Deadline: {jobData.deadline} | + {calculateDaysToExpiry(jobData.deadline)} days to expiry</li>
                    </ul>
                    <div>
                        <a onClick={() => deleteJob(jobData.id)} className="col-sm-3">
                            <button className="btn btn-danger btn-sm text-white float-end">
                                <span className="flaticon-plus"></span> Delete
                            </button>
                        </a>
                        <a href={"careers/view-job/" + jobData.id} className="col-sm-3">
                            <button className="btn btn-warning btn-sm text-white float-end me-3">
                                <span className="flaticon-plus"></span> Edit
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

const calculateDaysToExpiry = (deadline) => {
    const currentDate = new Date();
    const expiryDate = new Date(deadline);
    const timeDiff = expiryDate - currentDate;
    const daysToExpiry = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysToExpiry;
};

export default JobBlockAdmin;