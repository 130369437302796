import './App.scss';
import Navigation from './Components/Navigation/Navigation';
import Home from './Components/Home/Home';
import Services from './Components/Services/Services';
import About from './Components/About/About';
import Gallery from './Components/Gallery/Gallery';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
import {useState, useEffect} from 'react';
import Logo from './logo.png'
import Board from './Components/Board/Board';


function App() {
    const [header, setHeader] = useState("header")
    const [display, setDisplay] = useState("none")

    const listenScrollEvent = (event) => {
        if (window.scrollY < 73) {
            return setHeader("navigation")
        } else if (window.scrollY > 70) {
            return setHeader("navigation1")
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);
    return (
        <div className="App">
            <Navigation head={header}/>
            <Home/>
            <About/>

            <Services/>
            <Board/>

            <Gallery/>
            <Contact/>
            <Footer/>
            <div className="donate">
                <button
                    className="donate_button"
                    onClick={() => {
                        if (display === "none") {
                            setDisplay("block")
                        } else {
                            setDisplay("none")
                        }

                    }}>
                    Donate
                </button>


                <div style={{display: display}} className="donate_form">
                    <div className="close">
                        <button
                            onClick={() => {
                                setDisplay("none")
                            }}
                        >X
                        </button>
                    </div>
                    <img src={Logo} alt="ORTUM MISSION HOSPITAL"/>
                    <div className="quote">
                        <p>
                            "We make a living by what we get, but we make a life by what we give."
                        </p>
                    </div>
                    <div className="paybill">
                        <p>
                            Paybill no 4025785
                        </p>
                        <p>
                            Account Number: Your Name
                        </p>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default App;
