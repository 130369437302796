import React, { useEffect, useState } from 'react';
import './Careers.css';
import "./JobBlock";
import axios from 'axios';
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";
import JobBlock from "./JobBlock";

function Careers() {
    const [header, setHeader] = useState("header");
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const listenScrollEvent = () => {
        if (window.scrollY < 73) {
            return setHeader("navigation")
        } else if (window.scrollY > 70) {
            return setHeader("navigation1")
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    const [jobPosts, setJobPosts] = useState([]);

    useEffect(() => {
        let authToken = localStorage.getItem("authToken");

        axios.get(`${backendUrl}/api/job-posts/list`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(response => {
                console.log(response.data);
                setJobPosts(response.data);
            })
            .catch(error => {
                console.error('Error fetching job posts', error);
            });
    }, []);
    return (
        <div>
            <Navigation head={header} />
            <div className="page-wrapper">
                <h3 className={"page-title"}>Careers page</h3>
                <section className="job-section alternate">
                    <div className="auto-container">
                        {/* ... (other content) */}
                        <div className="default-tabs tabs-box">
                            {/* ... (other content) */}
                            <div className="tabs-content wow fadeInUp">
                                <div className="row">
                                    {jobPosts.length === 0 ? (
                                        <h3 className={"text-danger text-center"}>No jobs available. Please come back later</h3>
                                    ) : (
                                        <div className="row">
                                            {jobPosts.map(job => (
                                                <JobBlock key={job.id} jobData={job} />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ... (other content) */}
            </div>
            <Footer />
        </div>
    );
}

export default Careers;