import React from 'react';

const JobOverview = ({ data }) => {
    return (
        <div className="sidebar-widget">
            <h4 className="widget-title">Job Overview</h4>
            <div className="widget-content">
                <ul className="job-overview">
                    <li>
                        <i className="icon icon-calendar"></i>
                        <h5>Date Posted:</h5>
                        <span>{data.datePosted}</span>
                    </li>
                    <li>
                        <i className="icon icon-expiry"></i>
                        <h5>Expiration date:</h5>
                        <span>{data.deadline}</span>
                    </li>
                    <li>
                        <i className="icon icon-location"></i>
                        <h5>Location:</h5>
                        <span>{data.location}</span>
                    </li>
                    <li>
                        <i className="icon icon-user-2"></i>
                        <h5>Job Title:</h5>
                        <span>{data.positionTitle}</span>
                    </li>
                    <li>
                        <i className="icon icon-rate"></i>
                        <h5>Salary:</h5>
                        <span>{data.salary}</span>
                    </li>
                    <li>
                        <i className="icon icon-salary"></i>
                        <h5>Vacancies:</h5>
                        <span>{data.vacancies}</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default JobOverview;