import React from 'react'
import './Contact.scss'
import Heading from '../Heading/Heading'
import ContactItem from './ContactItem/ContactItem'
import Adress from './Adress.svg'
import Call from './Call.svg'
import Email from './Email.svg'
import Whatsapp from './Whatsapp.svg'
import Map from './Map/Map'
import Form from './Form/Form'
function Contact() {
  return (
    <div className='contact' id='contact'>
        <Heading upper='CONTACT' main='GET IN TOUCH'/>
        <div className="contact_items">
            <ContactItem refh={'https://maps.com'} title={'ADRESS'} icon={Adress} content={'P.O BOX 1312-30200 KITALE'}/>
            <ContactItem refh={'tel:0791028088'} title={'CONTACT NUMBER'} icon={Call} content={'Click or Copy 0791028088'}/>
            <ContactItem refh={'mailto:ortumhospital2010@gmail.com'} title={'EMAIL'} icon={Email} content={'ortumhospital2010@gmail.com'}/>
            <ContactItem refh={' https://api.whatsapp.com/send?phone=254791028088'} title={'WHATSAPP NOW'} icon={Whatsapp} content={"Click or Copy '254791028088'"}/>
        </div>
        <div className="map_form">
            <Map />
            <Form />

        </div>

    </div>
  )
}

export default Contact