import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import './tenderSingleDisplay.css';
import './tenderSingles.css';
import insu from "../../assets/insu.jpg"
import Navigation from "../../Navigation/Navigation";
import Footer from "../../Footer/Footer";
const TenderSinglePost = () => {
    const { id } = useParams();
    const [header, setHeader] = useState("header");
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const authToken = localStorage.getItem('authToken');
    const [tenderData, setTenderData] = useState({
        title: '',
        description: '',
        publishDate: '',
        closingDate: '',
        status: '',
        budget: 0,
        location: '',
        requirements: [],
    });

    const listenScrollEvent = () => {
        if (window.scrollY < 73) {
            return setHeader("navigation")
        } else if (window.scrollY > 70) {
            return setHeader("navigation1")
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${backendUrl}/api/tenders/${id}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });
                setTenderData(response.data);
            } catch (error) {
                console.error('Error fetching tender data:', error);
                // Handle error, e.g., set an error state or show a user-friendly message
            }
        };

        fetchData();
    }, [id]);

    return (
        <div className="page-wrapper">
            <Navigation head={header} />
            <div className="singleTenderPostDisplay">
                <div className="fullTenderDisplay">
                    <div className="singleTenderWrapper">
                        {/* Your image component here */}
                        <h1 className="singleTenderTitle">{tenderData.title}</h1>
                        <div className="singleTenderViewInfo">
                            <span className="singleTenderViewICategory">Location: {tenderData.location}</span>
                            <span className="singleTenderViewICategoryDate">
              Dates: {tenderData.publishDate} - {tenderData.closingDate}
            </span>
                            <span className="singleTenderViewICategory">Status: {tenderData.status}</span>
                            <span className="singleTenderViewICategory">Budget: {tenderData.budget}</span>
                        </div>
                        <p className="singleTenderDescription">{tenderData.description}</p>

                        {tenderData.requirements.map((requirement, index) => (
                            <div key={index}>
                                <h3>Requirement {index + 1}</h3>
                                <p>Description: {requirement.description}</p>
                                <p>Quantity: {requirement.quantity}</p>
                                <p>Specifications: {requirement.specifications}</p>
                            </div>
                        ))}

                        <button className="applyBtn">
                            <Link to= {`/careers/apply/${tenderData.title.toLowerCase().replace(/\s/g, '-')}-${tenderData.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                APPLY
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default TenderSinglePost;
