import React from 'react'
import './Map.scss'

function Map() {
  return (
    <div className="google_map_code">
    <iframe title='frame1'
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255280.0520795425!2d35.11686269868544!3d1.3254276868254307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1783a8d30569e295%3A0xc49b250ca29b3db4!2sOrtum%20Mission%20Hospital!5e0!3m2!1sen!2ske!4v1682513851459!5m2!1sen!2ske"
frameborder="0" 
      style={{border:0}} 
      allowfullscreen=""
       aria-hidden="false"
        tabindex="0">

        </iframe>
    </div>
  )}

export default Map

