import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {Routes, Route} from "react-router-dom"
import Admin from './Components/Admin/Admin';
import Careers from "./Components/Careers/Careers";
import CareersAdmin from "./Components/Admin/CareersAdmin"

import Gallery from './Components/Admin/Gallery';
import Login from './Components/Admin/Login/Login';
import JobDetail from "./Components/Careers/JobDetails/JobDetail";
import SurveyComponent from "./Components/Careers/JobApplication/SurveyComponent";
import TendersAdmin from "./Components/Admin/Tenders/Tenders";
import Example from "./Components/Admin/modal";

import SingleTender0 from './Components/TenderSinglePost';
import Tenders from "./Components/Tenders/Tenders";
import TenderDetail from "./Components/Tenders/TenderDetails/TenderDetail";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/" element={<App/>}/>
                <Route path="/example" element={<Example/>}/>

                <Route path="/careers" element={<Careers/>}/>
                <Route path={"/careers/view-job/:id"} element={<JobDetail/>} />
                <Route path={"/careers/apply/:id"} element={<SurveyComponent/>}/>

                <Route path="/tenders" element={<Tenders/>}/>
                <Route path={"/tenders/view-tender/:id"} element={<TenderDetail/>} />
                <Route path={"/tenders/apply/:id"} element={<SurveyComponent/>}/>

                <Route path="/admin" element={<Admin/>}>
                    <Route path="/admin/careers" element={<CareersAdmin/>}/>
                    <Route path="/admin/gallery" element={<Gallery/>}/>
                    <Route path="/admin/tenders" element={<TendersAdmin/>}/>
                </Route>
                <Route path="/login" element={<Login/>}/>
                <Route path="/Insurance-Tender" element={ <SingleTender0/> } /> ({/* Insurance */}
            </Routes>
        </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
