import React from 'react'

function Operations() {
  return (
    <div className="operations">
        <h6>OPERATIONS</h6>
        <p>
            Open 24/7
        </p>
    </div>
  )
}

export default Operations