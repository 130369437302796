import React from 'react'
import { useState } from 'react'
import './CoreValues.scss'
//react icons for value
import { FaHandHoldingHeart, FaHandshake, FaHandsHelping, FaHandshakeSlash,  FaHandHoldingMedical, FaHandHoldingWater } from 'react-icons/fa';
const BackImg='https://images.unsplash.com/photo-1579684385127-1ef15d508118?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80'
function CoreValues() {
    const values = [
        {
            id: 1,
            title: 'Respect',
            description: 'We are committed to the highest standards of integrity in all our dealings with our clients, suppliers, and employees.',
            icon:FaHandHoldingHeart
        },
        {
            id: 2,
            title: 'Care',
            description: 'We are committed to the highest standards of quality in all our dealings with our clients, suppliers, and employees.',
            icon: FaHandshake
        },
        {
            id: 3,
            title: 'Compassion',
            description: 'We are committed to the highest standards of teamwork in all our dealings with our clients, suppliers, and employees.',
            icon:FaHandHoldingMedical
        },
        {
            id: 4,
            title: 'Reliability',
            description: 'We are committed to the highest standards of integrity in all our dealings with our clients, suppliers, and employees.',
            icon:  FaHandsHelping
        },
        {
            id: 5,
            title: 'Patient Centered',
            description: 'We are committed to the highest standards of quality in all our dealings with our clients, suppliers, and employees.',
            icon: FaHandsHelping
        },
        {
            id: 6,
            title: 'Responsibility',
            description: 'We are committed to the highest standards of teamwork in all our dealings with our clients, suppliers, and employees.',
            icon:  FaHandshakeSlash
        },
        {
            id: 7,
            title: 'Quality',
            description: 'We are committed to the highest standards of teamwork in all our dealings with our clients, suppliers, and employees.',
            icon: FaHandHoldingMedical
        },
        {
            id: 8,
            title: 'Dedication',
            description: 'We are committed to the highest standards of teamwork in all our dealings with our clients, suppliers, and employees.',
            icon:FaHandHoldingWater
        },
        {
            id: 9,
            title: 'Teamwork',
            description: 'We are committed to the highest standards of teamwork in all our dealings with our clients, suppliers, and employees.',
            icon:  FaHandshakeSlash
        },
    ]
    const [activeValue, setActiveValue] =useState(values[0])
  return (
    <div className="core_values">
        <div className="values_mobile">

            {
                values.map((value) => {
                    return (
                        <div className="value" key={value.id}>
                            <div className="value_icon">
                                <FaHandHoldingHeart />
                            </div>
                            <div className="value_title">
                                {value.title}
                            </div>
                            <div className="value_desription">
                                {value.description}
                            </div>
                        </div>
                    )
                })

            }

        </div>

    <div className='values_desktop'>

            <div className="contain">
                {
                    values.map((value) => {
                        const Icon = value.icon
                        return (
                            <div onMouseEnter={()=>{setActiveValue(value)}} className="value" key={value.id}>
                                <div className="value_icon">
                                    <FaHandHoldingHeart />

                                </div>
                                <div className="value_title">
                                    {value.title}
                                </div>
                            </div>
                        )
                    }


                    )}
        
                </div>


       
            <div className="contents"
                style={{
                    backgroundImage: `url(${BackImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <div className="value_icon">
                    <div className="value_title">
                        {activeValue.title}
                    </div>
                    <div className="value_description">
                        {activeValue.description}
                    </div>
            </div>
            </div>
    </div>
    </div>

  )
}

export default CoreValues