import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'

import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import JobBlockAdmin from '../Careers/JobBlockAdmin';

import CreatableSelect from "react-select/creatable";

const CareersAdmin = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [jobPosts, setJobPosts] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [keyResponsibilitiesOptions, setKeyResponsibilitiesOptions] = useState([]);
    const [requirementsOptions, setRequirementsOptions] = useState([]);
    const [skillsOptions, setSkillsOptions] = useState([]);


    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        resetForm();
    };

    const [formData, setFormData] = useState({
        positionTitle: '',
        type: '',
        location: '',
        deadline: '',
        salary: '',
        vacancies: 0,
        datePosted: '',
        jobDescription: {
            description: '',
            keyResponsibilities: [],
            requirements: [],
            skills: [],
        },
    });

    const jobDescriptionData = {
        keyResponsibilities: formData.jobDescription.keyResponsibilities.map((option) => option.value),
        requirements: formData.jobDescription.requirements.map((option) => option.value),
        skills: formData.jobDescription.skills.map((option) => option.value),
    };

    const finalFormData = {
        positionTitle: formData.positionTitle,
        type: formData.type,
        location: formData.location,
        deadline: formData.deadline,
        salary: formData.salary,
        vacancies: formData.vacancies,
        datePosted: formData.datePosted,
        jobDescription: {
            description: formData.jobDescription.description,
            ...jobDescriptionData,
        },
    };

    const resetForm = () => {
        setFormData({
            positionTitle: '',
            type: '',
            location: '',
            deadline: '',
            salary: '',
            vacancies: 0,
            datePosted: '',
            jobDescription: {
                description: '',
                keyResponsibilities: [],
                requirements: [],
                skills: [],
            },
        });
    };

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleJobDescriptionChange = (event) => {
        const { value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            jobDescription: {
                ...prevData.jobDescription,
                description: value,
            },
        }));
    };


    const handleJobDescriptionFieldsChange = (field, selectedOptions) => {
        const updatedJobDescription = {
            ...formData.jobDescription,
            [field]: selectedOptions,
        };
        setFormData((prevData) => ({
            ...prevData,
            jobDescription: updatedJobDescription,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitting(true);

        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        let authToken = localStorage.getItem('authToken');

        console.log(formData)

        axios.post(`${backendUrl}/api/job-posts/create`, finalFormData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                console.log('Job created successfully:', response.data);
                closeModal(); // Reset the form and close the modal
                Swal.fire({
                    icon: 'success',
                    title: 'Job Created',
                    text: 'The job has been successfully created.',
                });
            })
            .catch((error) => {
                console.error('Error creating job:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An error occurred while creating the job. Please try again.',
                });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const backendUrl = process.env.REACT_APP_BACKEND_URL;
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get(`${backendUrl}/api/job-posts/list`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                setJobPosts(response.data);
                setLoading(false);

                const uniqueKeyResponsibilities = new Set();
                const uniqueRequirements = new Set();
                const uniqueSkills = new Set();

                response.data.forEach((job) => {
                    if (job && job.jobDescription) {
                        if (job.jobDescription.keyResponsibilities) {
                            job.jobDescription.keyResponsibilities.forEach(value => uniqueKeyResponsibilities.add(value));
                        }

                        if (job.jobDescription.requirements) {
                            job.jobDescription.requirements.forEach(value => uniqueRequirements.add(value));
                        }

                        if (job.jobDescription.skills) {
                            job.jobDescription.skills.forEach(value => uniqueSkills.add(value));
                        }
                    } else {
                        console.warn(`Job ${job.id} has no jobDescription or jobDescription is invalid.`);
                    }
                });

                const keyResponsibilitiesValues = Array.from(uniqueKeyResponsibilities);
                const requirementsValues = Array.from(uniqueRequirements);
                const skillsValues = Array.from(uniqueSkills);

                const keyResponsibilitiesOptions = keyResponsibilitiesValues.map(value => ({ label: value, value }));
                const requirementsOptions = requirementsValues.map(value => ({ label: value, value }));
                const skillsOptions = skillsValues.map(value => ({ label: value, value }));

                setKeyResponsibilitiesOptions(keyResponsibilitiesOptions);
                setRequirementsOptions(requirementsOptions);
                setSkillsOptions(skillsOptions);
            } catch (error) {
                console.error('Error fetching job posts', error);
                setError(error.message || 'An error occurred while fetching job posts.');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="page-wrapper">
            <button className="btn btn-primary m-3" onClick={openModal}>
                Add Job
            </button>

            <Container>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={8}>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="positionTitle">
                                <Form.Label>Position Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Software engineer"
                                    value={formData.positionTitle}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="type">
                                <Form.Label>Type</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Internship"
                                    value={formData.type}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="location">
                                <Form.Label>Location</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nyeri"
                                    value={formData.location}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="deadline">
                                <Form.Label>Deadline</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="2023-12-29"
                                    value={formData.deadline}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="salary">
                                <Form.Label>Salary</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Attractive"
                                    value={formData.salary}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="vacancies">
                                <Form.Label>Vacancies</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="5"
                                    value={formData.vacancies}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="datePosted">
                                <Form.Label>Date Posted</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="2023-12-29"
                                    value={formData.datePosted}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="jobDescription.description">
                                <Form.Label>Job Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter job description"
                                    value={formData.jobDescription.description}
                                    onChange={handleJobDescriptionChange}
                                />
                            </Form.Group>


                            <Form.Group controlId="jobDescription.keyResponsibilities">
                                <Form.Label>Key Responsibilities</Form.Label>
                                <CreatableSelect
                                    isMulti
                                    options={keyResponsibilitiesOptions}
                                    placeholder="Key Responsibilities"
                                    value={formData.jobDescription.keyResponsibilities}
                                    onChange={(selectedOptions) =>
                                        handleJobDescriptionFieldsChange('keyResponsibilities', selectedOptions)
                                    }
                                />
                            </Form.Group>

                            <Form.Group controlId="jobDescription.requirements">
                                <Form.Label>Requirements</Form.Label>
                                <CreatableSelect
                                    isMulti
                                    options={requirementsOptions}
                                    placeholder="Requirements"
                                    value={formData.jobDescription.requirements}
                                    onChange={(selectedOptions) =>
                                        handleJobDescriptionFieldsChange('requirements', selectedOptions)
                                    }
                                />
                            </Form.Group>

                            <Form.Group controlId="jobDescription.skills">
                                <Form.Label>Skills</Form.Label>
                                <CreatableSelect
                                    isMulti
                                    options={skillsOptions}
                                    placeholder="Skills"
                                    value={formData.jobDescription.skills}
                                    onChange={(selectedOptions) =>
                                        handleJobDescriptionFieldsChange('skills', selectedOptions)
                                    }
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>

            <section className="job-section alternate">
                <div className="auto-container">
                    <div className="default-tabs tabs-box">
                        <div className="tabs-content wow fadeInUp">
                            {loading ? (
                                <p>Loading job posts...</p>
                            ) : error ? (
                                <p className="text-danger text-center">{error}</p>
                            ) : jobPosts.length === 0 ? (
                                <h3 className={'text-danger text-center'}>No jobs available. Please come back later</h3>
                            ) : (
                                <div className="row">
                                    {jobPosts.map((job) => (
                                        <JobBlockAdmin key={job.id} jobData={job} />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {/* Modal */}
            <Modal show={isModalOpen} onHide={closeModal} scrollable={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col xs={12} md={8}>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="positionTitle">
                                        <Form.Label>Position Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Software engineer"
                                            value={formData.positionTitle}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="type">
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Internship"
                                            value={formData.type}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="location">
                                        <Form.Label>Location</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Nyeri"
                                            value={formData.location}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="deadline">
                                        <Form.Label>Deadline</Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder="2023-12-29"
                                            value={formData.deadline}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="salary">
                                        <Form.Label>Salary</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Attractive"
                                            value={formData.salary}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="vacancies">
                                        <Form.Label>Vacancies</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="5"
                                            value={formData.vacancies}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="datePosted">
                                        <Form.Label>Date Posted</Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder="2023-12-29"
                                            value={formData.datePosted}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="jobDescription.description">
                                        <Form.Label>Job Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Enter job description"
                                            value={formData.jobDescription.description}
                                            onChange={handleJobDescriptionFieldsChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="jobDescription.keyResponsibilities">
                                        <Form.Label>Key Responsibilities</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Enter key responsibilities, separated by commas"
                                            value={formData.jobDescription.keyResponsibilities}
                                            onChange={handleJobDescriptionFieldsChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="jobDescription.requirements">
                                        <Form.Label>Requirements</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Enter requirements, separated by commas"
                                            value={formData.jobDescription.requirements}
                                            onChange={handleJobDescriptionFieldsChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="jobDescription.skills">
                                        <Form.Label>Skills</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Enter skills, separated by commas"
                                            value={formData.jobDescription.skills}
                                            onChange={handleJobDescriptionFieldsChange}
                                        />
                                    </Form.Group>

                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={submitting}>
                        Save changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CareersAdmin;
