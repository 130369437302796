import React from 'react';
function JobBlock({ jobData }) {
    return (
        <div className="job-block col-lg-6 col-md-12 col-sm-12">
            <div className="inner-box">
                <div className="content">
                    <h4><a href={jobData.link}>{jobData.positionTitle} - <span className="text-muted">Ortum Mission Hospital</span></a></h4>
                    {/*<h4><a href={jobData.link}>{jobData.positionTitle} - <span className="text-muted">{jobData.companyName}</span></a></h4>*/}
                    <ul className="job-info job-other-info">
                        <li className="time"><i className="fi fi-rr-briefcase"></i> {jobData.type}</li>
                        <li className="privacy"><i className="fi fi-rr-money"></i> {jobData.salary}</li>
                        <li className="required"><i className="fi fi-rr-map-marker"></i> Location: {jobData.location}</li>
                        <hr />
                        <li> <i className="fi fi-rr-clock-three"></i> Deadline: {jobData.deadline} | + {calculateDaysToExpiry(jobData.deadline)} days to expiry</li>
                    </ul>
                    <div className="row">
                        <a href={"careers/view-job/" + jobData.id} className="col-sm-3 offset-sm-9">
                            <button className="btn btn-warning btn-sm text-white float-end">
                                <span className="flaticon-plus"></span> Apply
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

const calculateDaysToExpiry = (deadline) => {
    const currentDate = new Date();
    const expiryDate = new Date(deadline);
    const timeDiff = expiryDate - currentDate;
    const daysToExpiry = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysToExpiry;
};

export default JobBlock;