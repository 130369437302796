import React from 'react'
import './Heading.scss'

function Heading({upper, main}) {
  return (
    <div className="section_heading">
    <h6 className="upper">
      {upper}

    </h6>
    <h2 className="main">
      {main}

    </h2>
  </div>
)
}

export default Heading