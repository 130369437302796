import React, {useEffect, useState} from 'react'
import Logo from '../../logo.png'
import {Link, Outlet, useNavigate} from 'react-router-dom'
import './Admin.scss'
import NavigationAdmin from "../Navigation/NavigationAdmin";

const AdminNav = () => {
    const navigate = useNavigate();
    return (
        <div>

            <div className='admin-nav'>
                <Link to='/' className="logo-title">
                    <img src={Logo} alt="logo"/>
                    <h2>Admin</h2>
                </Link>
                <div className="admin-header">
                    <Link
                        to='/admin/gallery'
                        className="admin-header-link link1"
                    >
                        <div>Gallery</div>
                    </Link>
                    <Link
                        to='/admin/careers'
                        className="admin-header-link link2"
                    >
                        <div>Careers</div>
                    </Link>
                </div>
                <div className="signout-user">
                    <div
                        onClick={() => {
                            localStorage.removeItem('user');
                            navigate('/login');
                        }
                        }
                        className='sign-out'>
                        Signout
                    </div>
                    <div className='user'>
                        User
                    </div>
                </div>
            </div>
        </div>
    )
}

const AdminHeader = () => {
    return (
        <div className="admin-header">
            <Link
                to='/admin/gallery'
                className="admin-header-link link1"
            >
                <div>Gallery</div>
            </Link>
            <Link
                to='/admin/careers'
                className="admin-header-link link2"
            >
                <div>Careers</div>
            </Link>
        </div>
    )
}

const AdminFooter = () => {
    return (
        <div
            className="admin-footer"
        >
            copyrights @ Walgotech 2023
        </div>
    )
}

function Admin() {
    const [header, setHeader] = useState("header")
    const navigate = useNavigate();

    const listenScrollEvent = () => {
        if (window.scrollY < 73) {
            return setHeader("navigation")
        } else if (window.scrollY > 70) {
            return setHeader("navigation1")
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);


    useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user) {
            navigate('/login');
        }
    }, [navigate]);


    return (
        <div className="admin">
            <NavigationAdmin head={header}/>
            <div className="outlet">
                <Outlet/>
            </div>
            <AdminFooter/>
        </div>
    )
}

export {AdminNav, AdminHeader, AdminFooter}
export default Admin
