import React from 'react'
import './Links.scss'
import Link from './links.svg'

function Links() {
  return (
    <div className='footer_links'>
      <h6>LINKS</h6>
      <ul>
        <li><a href='#home' ><img src={Link} alt="" />Home</a></li>
        <li><a href='#about' ><img src={Link} alt="" />Services</a></li>
        <li><a href='#contact' ><img src={Link} alt="" />About</a></li>
        <li><a href='#testimonials' ><img src={Link} alt="" />Testimonials</a></li>
        <li><a href='#contact' ><img src={Link} alt="" />Contact</a></li>
        

      </ul>
    </div>
  )
}

export default Links