import React from 'react'
import './ContactItem.scss'

function ContactItem({refh,title, icon, content}) {
  return (
    <div className='contact_item'>
       <a  href={refh} target='blank' >

        <div className="icon_container">
          <img src={icon} alt={title} /> 


        </div>
        <div className="content_container">
        <h6 className='title'>{title}</h6>
        <p className='content'>{content}</p>

        </div>
        </a>


    </div>
  )
}

export default ContactItem