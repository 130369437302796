import React from 'react'
import './Navigation.scss'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from './logo.png';

function NavigationAdmin({head}) {

  return (
    <Navbar className={head} fixed="top" collapseOnSelect expand="lg"  variant="light">
      <Container className='nav_container'>
        <Navbar.Brand href="#home"><div className="logo"><img src={Logo} alt="Ortum" /></div></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto nav_links">
            <Nav.Link className='nav_link home_link' href="/admin">Home</Nav.Link>
            <Nav.Link className='nav_link' href="/admin/gallery">Gallery</Nav.Link>
            <Nav.Link className='nav_link' href="/admin/careers">Careers</Nav.Link>
            <Nav.Link className='nav_link' href="/admin/tenders">Tenders</Nav.Link>
            {/*<Nav.Link className='nav_link' href="/example">Example</Nav.Link>*/}
            {/*<div className="signout-user">
              <div
                  onClick={() => {
                    localStorage.removeItem('user');
                  }
                  }
                  className='sign-out'>
                Signout
              </div>
              <div className='user'>
                User
              </div>
            </div>*/}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationAdmin;