// JobDescription.js
import React from 'react';

function JobDescription({ jobDescription }) {
    return (
        <div className="job-description">
            <h2>Job Description</h2>
            <p>{jobDescription.description}</p>
            <div>
                <h3>Key Responsibilities</h3>
                <ul>
                    {jobDescription.keyResponsibilities.map((responsibility, index) => (
                        <li key={index}>{responsibility}</li>
                    ))}
                </ul>
            </div>
            <div>
                <h3>Requirements</h3>
                <ul>
                    {jobDescription.requirements.map((requirement, index) => (
                        <li key={index}>{requirement}</li>
                    ))}
                </ul>
            </div>
            <div>
                <h3>Skills</h3>
                <ul>
                    {jobDescription.skills.map((skill, index) => (
                        <li key={index}>{skill}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default JobDescription;
