import React from 'react'
import './About.scss'
import Twitter  from './Twitter.svg'
import Ig from './Ig.svg'
import WhatsApp from './WhatsApp.svg'
import Facebook from './Facebook.svg'

function About() {
  return (
    <div className='footer_about'>
      <h6>ABOUT ORTUM MISSION HOSPITAL</h6>
      <p>
      Ortum Mission Hospital is a Catholic faith-based Mission Hospital situated in Pokot South Sub-county, West Pokot County. Our vision is to Offer an Effective, Efficient and High Quality Health Care system that is
Accessible, Equitable and affordable to all.

      </p>
      
      <div className="social">
        <img src={Twitter} alt="" />
        <img src={Ig} alt="" />
        <img src={WhatsApp} alt="" />
        <img src={Facebook} alt="" />
        </div>

    </div>
  )
}

export default About