const backendUrl = process.env.REACT_APP_BACKEND_URL;
export const json = {
    "title": "Job Application Form",
    "description": "Thank you for your interest in working with us. Please fill out the form and send your application. We will get back to you soonest possible.",
    "logo": backendUrl + "/static/logo.png",
    "questionErrorLocation": "bottom",
    "logoHeight": "100px",
    "logoWidth": "100px",
    "logoPosition": "left",
    "elements": [{
        "type": "panel",
        "name": "personal-info",
        "title": "Personal Information",
        "elements": [{
            "type": "text",
            "name": "firstName",
            "title": "First name",
            "isRequired": true
        }, {
            "type": "text",
            "name": "lastName",
            "startWithNewLine": false,
            "title": "Last name",
            "isRequired": true
        }, {
            "type": "text",
            "name": "email",
            "title": "Email",
            "inputType": "email",
            "isRequired": true,
            "placeholder": "mail@example.com"
        }, {
            "type": "text",
            "name": "phone",
            "title": "Phone",
            "inputType": "tel",
            "isRequired": true,
            "placeholder": "+1 000 000 000"
        }, {
            "type": "text",
            "name": "dob",
            "title": "Date of birth",
            "inputType": "date",
            "isRequired": true
        }]
    }, {
        "type": "panel",
        "name": "location",
        "title": "Your Location",
        "elements": [{
            "type": "dropdown",
            "name": "country",
            "title": "Country",
            "choicesByUrl": {
                "url": "https://surveyjs.io/api/CountriesExample"
            }
        }, {
            "type": "text",
            "name": "city",
            "title": "City/Town"
        }, {
            "type": "text",
            "name": "zip",
            "startWithNewLine": false,
            "title": "Zip code",
            "inputType": "number",
            "validators": [{
                "type": "numeric"
            }]
        }, {
            "type": "text",
            "name": "address",
            "title": "Street address"
        }]
    }, {
        "type": "file",
        "name": "cv",
        "title": "Upload your resume",
        "acceptedTypes": "application/pdf|application/msword",
        "waitForUpload": true,
        "storeDataAsText": false
    }],
    "showQuestionNumbers": false,
    "completeText": "Apply",
    "showCompletedPage": false,
    "widthMode": "static",
    "width": "800px"
};