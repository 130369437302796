import React, {useState} from 'react';
import axios from 'axios';
import './Login.scss';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const authToken = localStorage.getItem('authToken');

    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;


    const handleLogin = async () => {
        try {
            axios.post(`${backendUrl}/api/auth/signin`,
                {
                    username,
                    password,
                },
                {
                    Headers: {
                        'Content-Type': 'application/json',
                    },

                }
            ).then(
                (response) => {
                    //local storage store user
                    localStorage.setItem('user', JSON.stringify(response.data));
                    let authToken = response.data.token;
                    localStorage.setItem("authToken", authToken);
                    console.log(response.data);
                    //redirect to admin page
                    window.location.href = '/admin';
                }
            );

        } catch (error) {
            console.error("error", error);
        }
    };

    return (
        <div className="login">s
            <div className="login-form">
                <div className="login-title">
                    <h2>Admin Login</h2>
                </div>
                <div className="login-inputs">
                    <div className="login-input">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            name="username"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="login-input">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className="login-button">
                    <button onClick={handleLogin}>Login</button>
                </div>
            </div>
        </div>
    );
}

export default Login;
