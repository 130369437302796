import React from 'react';

const JobHeader = ({ data }) => {
    return (
        <div className="job-block-seven style-two">
            <div className="inner-box">
                <div className="content">
                    <h4><a href="#">{data.positionTitle}</a></h4>
                    <ul className="job-info">
                        <li><span className="icon flaticon-briefcase"></span> Type: {data.type}</li>
                        <li><span className="icon flaticon-map-locator"></span> Location: {data.location}</li>
                        <li><span className="icon flaticon-clock-3"></span> Deadline: {data.deadline}</li>
                        <li><span className="icon flaticon-money"></span> Salary: {data.salary}</li>
                    </ul>
                    <a href={`/careers/apply/${data.positionTitle.toLowerCase().replace(/\s/g, '-')}-${data.id}`} className="theme-btn btn-style-one mobile-search">Apply Now</a>
                </div>
            </div>
        </div>
    );
};

export default JobHeader;
