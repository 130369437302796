import React from 'react'
import './About.scss'
import  Heading  from  '../Heading/Heading'
import CoreValues from './CoreValues/CoreValues'
import AboutImg from './About.jpg'

function About() {
  return (
    <div id='about'  className='about'>
        <Heading upper='ABOUT' main='ABOUT US' />
        <div  className='about_container'>
          <div className="about_content">
            <div className="image">
              <img 
              src={AboutImg}
               alt="" />

            </div>
            <div className="text">

            <h3>WE ARE A MISSION LED HOSPITAL</h3>
            <p>
            Ortum Mission Hospital is a Catholic faith-based Mission Hospital situated in Pokot South Sub-county, West Pokot County. 
            It was started in 1956 by the Holy Rosary Sisters under the Catholic Diocese of Eldoret Currently Catholic Diocese of  Kitale.
              It is a registered level four health facility with a bed capacity of 120.
               Ortum Mission Hospital is the second largest hospital in the County after Kapenguria Referral Hospital and the only mission Hospital in the larger West Pokot County. 
               In pursuit of its core functions and objectives, the hospital endeavors to provide both physical and spiritual healing by dispensing holistic quality health care services as well as spiritual counseling to needy people in West Pokot County itself, TransNzoia, Turkana and some parts of Baringo East Counties, in line with the Hospital’s calling and declared responsibility of alleviating human suffering as a service to God. “Where there is sickness, to bring healing and cure”. The Hospital has a school of nursing namely Ortum School of Nursing that is registered with the Nursing Council of Kenya. It was started in 1969 and is currently training Kenya Registered Community Health Nurses (KRCHN). 
            </p>
       
              </div>


          </div>
          <div className="treat">
            <div>
              "WE TREAT GOD HEALS"
            </div>
          </div>

          </div>
          <div className="mission">
          <Heading  upper='VISION' main='OUR VISION' />

          <p>
          To Offer an Effective, Efficient and High Quality Health Care system that is Accessible, Equitable and affordable to all.
          </p>

      
          <Heading  upper='CORE VALUES' main='OUR CORE VALUES' />

          <CoreValues />
          <Heading  upper='MISSION' main='OUR MISSION' />
          <p>
          To promote and participate in provision of integrated and high Quality Promotive, Preventive, Curative and Rehabilitative Health Care services to all.
          </p>



          </div>
    

    </div>
  )
}

export default About