import React from 'react'
import './Services.scss'
import {  Link } from 'react-router-dom'
import Heading from '../Heading/Heading'
import {CiMedicalCase} from 'react-icons/ci'

const  SERVICES = [
  {
    id:"1",
    title:"Medical Services",
    description:"Our team of experienced healthcare professionals is dedicated to providing high-quality medical care to our patients. We offer a range of medical services, including outpatient and inpatient care, orthopaedics, laboratory tests, X-rays, pharmacy services, ultrasound, theatre services, TB programme, comprehensive care clinic, hypertensive clinic, and antenatal mothers unit (Kiror).",
    icon:"",
    url:"",
    source:"https://images.unsplash.com/photo-1516574187841-cb9cc2ca948b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
  },
  {
    id:"2",
    title:"Maternity Services",
    description:"We provide comprehensive care for expectant mothers and newborns through our maternity services, which include a maternity/newborn unit (NBU), maternal and child health (MCH) and prevention of mother-to-child transmission of HIV (PMTCT) services, and the Linda Mama program.",
    icon:"",
    url:"",
    source:"https://images.unsplash.com/photo-1532938911079-1b06ac7ceec7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80"
  },
  {
    id:"3",
    title:"Diagnostic Services",
    description:"Our diagnostic services are designed to help you get the accurate information you need to make informed decisions about your health. We offer laboratory tests, X-rays, ultrasound, and voluntary counselling and testing (VCT) services.",
    icon:"",
    url:"",
    source:"https://images.unsplash.com/photo-1519494080410-f9aa76cb4283?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=874&q=80"
  },
  {
    id:"4",
    title:"Heart and Wellness Services",
    description:"We offer a range of services to help you maintain a healthy heart and overall wellness. Our services include Health Heart Africa (HHA) services and hypertensive clinic.",
    icon:"",
    url:"",
    source:"https://images.unsplash.com/photo-1530026405186-ed1f139313f8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"

  },
  {
    id:"5",
    title:"Insurance and Payment Services",
    description:"We are NHIF accredited, making it easy for you to access our services through your insurance.",
    icon:"",
    url:"",
    source:"https://images.unsplash.com/photo-1505751172876-fa1923c5c528?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
  },
  {
    id:"6",
    title:"Support Services",
    description:"We understand that sometimes you need additional support to manage your healthcare needs. That's why we offer ambulance services and amenity services to make your experience as comfortable as possible.",
    icon:"",
    url:"",
    source:"https://plus.unsplash.com/premium_photo-1658506854326-52eab70cb236?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHhttps://plus.unsplash.com/premium_photo-1658506854326-52eab70cb236?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80xwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
  },


]

const Service= ({title, description, icon, url}) => {
  return (

    <div className='service'>
      <div className='service_icon'>
        <CiMedicalCase
          style={{
            fontSize: '1.5rem',
            color: '#fff'
          }}

         />
      </div>
      <div className='service_content'>
        <h3>{title}</h3>
        <p>{description}</p>
        <Link to={url}>Learn More</Link>
      </div>
    </div>

  )

}
const Image = ({source, alt}) => {
  return (
      <div className="service_image">
        <img src={source} alt={alt} />
      </div>
    )
}

function Services() {
  return (
    <div
    id='services'
     className='services'>
      <Heading upper='SERVICES' main='OUR SERVICES' />
      <div
      className='services_container'
      >
        <div className='service_container'>
          <Service
            title={SERVICES[0].title}
            description={SERVICES[0].description}
            icon={SERVICES[0].icon}
          />
          <Image
          source={SERVICES[0].source}
          alt={SERVICES[0].title}
          />
          </div>
          <div className='service_container'>
          <Image
          source={SERVICES[1].source}
          alt={SERVICES[1].title}
          />
          <Service
            title={SERVICES[1].title}
            description={SERVICES[1].description}
            icon={SERVICES[1].icon}
          />
        
          </div>
          <div className='service_container'>
          <Service
            title={SERVICES[2].title}
            description={SERVICES[2].description}
            icon={SERVICES[2].icon}
          />
          <Image
          source={SERVICES[2].source}
          alt={SERVICES[2].title}
          />
          </div>
          <div className='service_container'>
          <Image
          source={SERVICES[3].source}
          alt={SERVICES[3].title}
          />
          <Service
            title={SERVICES[3].title}
            description={SERVICES[3].description}
            icon={SERVICES[3].icon}
          />
        
          </div>
          <div className='service_container'>
          <Service
            title={SERVICES[4].title}
            description={SERVICES[4].description}
            icon={SERVICES[4].icon}
          />
          <Image
          source={SERVICES[4].source}
          alt={SERVICES[4].title}
          />
          </div>
          <div className='service_container'>
          <Image
          source={SERVICES[5].source}
          alt={SERVICES[5].title}
          />
          <Service
            title={SERVICES[5].title}
            description={SERVICES[5].description}
            icon={SERVICES[5].icon}
          />
        
          </div>

          

      </div>

    </div>
  )
}

export default Services