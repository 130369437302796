import React, {useEffect, useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from "sweetalert2";
import axios from "axios";
import JobBlockAdmin from "../Careers/JobBlockAdmin";

const Gallery = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    let authToken = localStorage.getItem("authToken");
    const [images, setImages] = useState([])
    let subtitle;

    const [isModalOpen, setModalOpen] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);
    const [caption, setCaption] = useState('');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        Swal.fire({
            timerProgressBar: true,
            title: 'Sending',
            text: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            },
        });

        const formData = new FormData();
        formData.append('pictureFile', selectedImage);
        formData.append('caption', caption);
        formData.append('morepath', 'gallery')
        let authToken = localStorage.getItem("authToken");
        axios.post(`${backendUrl}/upload`, formData, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.message,
                });
                closeModal();
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                });
            });
    };

    //delete image
    const handleDelete = (id) => {

        Swal.fire({
            timerProgressBar: true,
            title: 'Deleting',
            text: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            },
        });

        axios.get(`${backendUrl}/api/gallery/delete/${id}` , {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data,
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error,
                });
                console.error('Error deleting image', error);
            });
    };

    useEffect(() => {
        let authToken = localStorage.getItem("authToken");

        axios.get(`${backendUrl}/api/gallery/list`, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then((response) => {
                console.log(response.data)
                setImages(response.data);
            })
            .catch((error) => {
                console.error('Error fetching images', error);
            });
    }, []);

    const GalleryItem = ({image, caption, id}) => {
        return (
            <div className="gallery-item">
                <div className="gallery-item-img">
                    <img src={image} alt="gallery"/>
                </div>
                <div className="gallery-item-caption">
                    {caption}
                </div>
                <div className="gallery-buttons">
                    <button
                        onClick={() => handleDelete(id)}
                        className="gallery-btn delete">
                        Delete
                    </button>

                </div>

            </div>
        )
    }
    return (
        <div className="page-wrapper">
            <h3 className={"page-title"}>Gallery Management</h3>
            <button className="btn btn-primary m-3" onClick={openModal}>Add Image</button>

            <div className="gallery">
                <div className="gallery-body">
                    {
                        images.map((image, index) => {
                            return (
                                <GalleryItem
                                    key={index}
                                    image={`${backendUrl}/` + image.url}
                                    caption={image.caption}
                                    id={image.id}
                                />
                            )
                        })
                    }

                </div>
            </div>

            {/* Modal */}
            <Modal show={isModalOpen} onHide={closeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input type="file" onChange={(e) => setSelectedImage(e.target.files[0])}/>
                    <input type="text" placeholder="Caption" onChange={(e) => setCaption(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Gallery;
