import React from 'react'
import './Form.scss'
import Button from 'react-bootstrap/Button';
import {useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import Swal from 'sweetalert2';

function Form() {
    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });


    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    })


    const validateForm = (fieldName, value) => {
        let err;
        const formErrors = errors;
        switch (fieldName) {
            case 'name':
                err = value.length < 3 ? 'Name must be atleast 3 characters' : ''
                formErrors.name = err;
                setErrors(formErrors)
                break;
            case 'email':
                //validate email
                const valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                err = valid ? '' : 'Email is not valid'
                formErrors.email = err;
                setErrors(formErrors)
                break;
            case 'phone':
                err = value.length < 6 ? 'Phone must be atleast 6 characters' : ''
                formErrors.phone = err;
                setErrors(formErrors)
                break;

            case 'message':
                err = value.length < 15 ? 'Message must be longer than 15 characters' : ''
                formErrors.message = err;
                setErrors(formErrors)
                break;
            default:

        }
    }

    const onUpdateField = e => {
        const nextFormState = {
            ...form,
            [e.target.name]: e.target.value,
        };
        setForm(nextFormState);
    };

    const onSubmitForm = async (e) => {
        e.preventDefault();

        Swal.fire({
            timerProgressBar: true,
            title: 'Sending',
            text: 'Please wait...',
            didOpen: () => {
                Swal.showLoading();
            },
        });

        if (Object.values(errors).some(err => err !== '')) {
            toast.error("Error, invalid input");
        }

        try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const response = await fetch(`${backendUrl}/submit-form`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(form),
            });

            const result = await response.json();

            console.log(result)
            if (result.code === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: result.response,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: result.response,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                footer: <p>{error}</p>,
            });
            console.error('Error submitting form:', error);
        }
    };

    return (
        <form onSubmit={onSubmitForm}>
            <div className="form_container">
                <div className="input_container">
                    <input name='name' onChange={(e) => {
                        onUpdateField(e);
                        validateForm(e.target.name, e.target.value)
                    }} type="text" placeholder='Name'/>
                    <div style={{color: "red"}} className="error">{errors.name}</div>
                    <input name='email' onChange={(e) => {
                        onUpdateField(e);
                        validateForm(e.target.name, e.target.value)
                    }} type="email" placeholder='Email'/>
                    <div style={{color: "red"}} className="error">{errors.email}</div>
                    <input name='phone' onChange={(e) => {
                        onUpdateField(e);
                        validateForm(e.target.name, e.target.value)
                    }} type="text" placeholder='Phone'/>
                    <div style={{color: "red"}} className="error">{errors.phone}</div>
                </div>
                <div className="textarea_container">
        <textarea name='message' onChange={(e) => {
            onUpdateField(e);
            validateForm(e.target.name, e.target.value)
        }} id="message" cols="30" rows="5" placeholder='Message'></textarea>
                    <div style={{color: "red"}} className="error">{errors.message}</div>
                </div>
            </div>
            <Button variant="primary" className='submit' type="submit">SEND MESSAGE</Button>
            <ToastContainer
                className={'toast'}
                style={{width: "300px", height: "10px"}}
                position="top-center"
                autoClose={5000}
                // hideProgressBar={false}
                // newestOnTop={false}
                // closeOnClick
                // rtl={false}
                // pauseOnFocusLoss
                // draggable
                // pauseOnHover
            />
        </form>
    )
}

export default Form