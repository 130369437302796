import React from 'react'
import './Board.scss' 
const url = process.env.PUBLIC_URL;
const boardMembers = [
    {
      id: 1,
      name: "Rt. Rev. Bishop Henry Juma Odonya",
      role: "Chairperson",
      imageUrl: "/Images/Henry.jpg",
    },
    {
      id: 2,
      name: "Sr. Prexadis Muthuka",
      role: "Administrator",
      imageUrl: "https://example.com/board/jane-doe.jpg",
    },
    {
      id: 3,
      name: "Sr. Nelly Kiplagat",
      role: "Principal School of Nursing",
      imageUrl: "https://example.com/board/tom-jones.jpg",
    },
    {
      id: 4,
      name: "Sr. Violet Wamalwa",
      role: "Hospital Matron",
      imageUrl: "https://example.com/board/sara-johnson.jpg",
    },
    {
      id: 5,
      name: "Sr. Mary Chebet",
      role: "Accountant",
      imageUrl: "/Images/Chebet.jpeg",
    },
    {
      id: 6,
      name: "Dr. Alex Injemela",
      role: "Medical Officer",
      imageUrl: "/Images/Alex.jpeg",
    },

  ];
  const BoardMember = ({ member }) => {
    return (
        <div className="board-member">
          <div className="member_image">
          <img src={url+member.imageUrl} alt={member.name} />
          {/* <img src={member.imageUrl} alt={member.name} /> */}

          </div>
            <h3 className='member-name'>{member.name}</h3>
            <p>{member.role}</p>
        </div>
        );
    };
    

function Board() {
  return (
    <div className='board'>
        <div className="board-header">
            <h1>Board of Directors</h1>
        </div>
        <div className="board-members">
            {boardMembers.map((member) => (
                <BoardMember member={member} key={member.id} />
            ))}
        </div>
    </div>

  )
}

export default Board